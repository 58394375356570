import { Component, Input, OnInit } from '@angular/core';
import { CurrencyPipe, NgClass } from '@angular/common';
import { UserSettings } from '../../../models/user-settings';

@Component({
  standalone: true,
  selector: 'app-earnings-summary',
  templateUrl: './earnings-summary.component.html',
  styleUrls: ['./earnings-summary.component.scss'],
  imports: [NgClass, CurrencyPipe]
})
export class EarningsSummaryComponent implements OnInit {
  @Input() parentName: string;
  @Input() public earnings: number;
  @Input() public billedHours: number;
  @Input() public numberOfTasks: number;
  @Input() public titles: Array<String>;
  @Input() globalSettingsModel: UserSettings;
  public typeOfCurrency: string;

  constructor() { }

  ngOnInit() {
    if (this.globalSettingsModel.currency) {
      this.typeOfCurrency = this.globalSettingsModel.currency;
    }
  }
}
