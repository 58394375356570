<div [ngClass]="parentName" id="scroll">
  <div class="itemContainer">
    <div class="itemCard">
      <div class="textContent">
        <p class="primary">{{ earnings ? (earnings | currency: typeOfCurrency) : '0' }}</p>
        <p class="subText">{{ titles[0] }}</p>
      </div>
    </div>
    <div class="itemCard">
      <div class="textContent">
        <p class="primary">{{ billedHours }}</p>
        <p class="subText">{{ titles[1] }}</p>
      </div>
    </div>
    <div class="itemCard">
      <div class="textContent">
        <p class="primary">{{ numberOfTasks }}</p>
        <p class="subText">{{ titles[2] }}</p>
      </div>
    </div>
  </div>
</div>
